import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SALES_PACKAGE_BASE_FRAGMENT = `
  fragment salesPackageBaseFragment on SalesPackage {
    __typename
    uid
    title
    description
    variantName
    variantGroup
    price
    includedByDefault
    maximumQuantity
    maximumQuantityPerExhibitor
    needsApproval
    rank
    creationTime
    updateTime
    childSalesPackages {
      uid
      price
      variantName
      fileResource {
        ...fileResourceBaseFragment
      }
    }
    parentSalesPackage {
      uid
      variantName
      price
      fileResource {
        ...fileResourceBaseFragment
      }
      childSalesPackages {
        uid
        price
        variantName
        fileResource {
          ...fileResourceBaseFragment
        }
      }
    }
    _stock"%filter%" {
      uid
      quantity
    }
    _isPurchased"%filter%"
    images {
      ...fileResourceBaseFragment
    }
    fileResource {
      ...fileResourceBaseFragment
    }
    documentationFileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
